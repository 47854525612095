@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('fonts/Rubik-300.woff2') format('woff2'); /* Super Modern Browsers */
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('fonts/Rubik-500.woff2') format('woff2'); /* Super Modern Browsers */
  font-display: swap;
}


html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

body {
    line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display:block;
}

nav ul {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}

/* Change colours to your needs */
ins {
    background-color:#bababa;
    color:#000;
    text-decoration:none;
}

/* Change colours to your needs */
mark {
    background-color:#bababa;
    color:#000;
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

/* Change border-color to your needs */
hr {
    display:block;
    height:1px;
    border:0;
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

input, select {
    vertical-align:middle;
}

#clear,
#clearer,
.clear,
.clearer {
    clear:both;
}

.hide,
.invisible {
    display:none;
}

.hidden {
    visibility:hidden;
}

.visible {
    visibility:visible;
}

.mobileOnly {
  display: none;
}

html,
body {
  height: auto;
  min-height: 100%;
}

body {
  position: relative;
  margin: 0px;
  padding: 0px;

  background-color: #FFFFFF;

  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #151515;
  line-height: 22px;

  background-image: -webkit-linear-gradient(right, #443ad2, #8349d1);
  background-image: -moz-linear-gradient(right, #443ad2, #8349d1);
  background-image: -o-linear-gradient(right, #443ad2, #8349d1);
  background-image: -ms-linear-gradient(right, #443ad2, #8349d1);
  background-image: linear-gradient(to left, #443ad2, #8349d1);

}

a {
  color: #6141d2;
  text-decoration: underline;

  transition: color .5s ease-in-out;
  -moz-transition: color .5s ease-in-out;
  -webkit-transition: color .5s ease-in-out;
}

a:hover {
  color: #000000;
  text-decoration: none;
}

p {
  margin-bottom: 20px;
}

.hide {
  display:none;
}


strong {
  font-weight: 500;
}

.centerText {
  text-align: center;
}

.leftText {
  text-align: left;
}

.rightText {
  text-align: right;
}

.underlined {
  text-decoration: underline;
}

.mobileOnly {
  display: none;
}

h1 {
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  font-size: 40px;
  line-height: 55px;
  margin: 0px;
  padding: 10px 0px 10px 80px;
  text-align: left;
  color: #FFFFFF;
  text-shadow: 0px 3px 5px rgba(0,0,0,0.3);
  text-transform: uppercase;

  background: url(images/miva-symbol-white.svg) no-repeat center left;
  background-size: 60px auto;

  position: relative;
  left: 50px;
  top: 35px;
  display: inline;
}

h2 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 55px;
  margin-bottom: 5px;
  color: #6141d2;
}

h3 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
}


h2 + p {
  width: 66%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 60px;
}



.minervaGradient {
  background-image: -webkit-linear-gradient(right, #443ad2, #8349d1);
  background-image: -moz-linear-gradient(right, #443ad2, #8349d1);
  background-image: -o-linear-gradient(right, #443ad2, #8349d1);
  background-image: -ms-linear-gradient(right, #443ad2, #8349d1);
  background-image: linear-gradient(to left, #443ad2, #8349d1);
}

.minervaGradientInv {
  background-image: -webkit-linear-gradient(right, #8349d1, #443ad2);
  background-image: -moz-linear-gradient(right, #8349d1, #443ad2);
  background-image: -o-linear-gradient(right, #8349d1, #443ad2);
  background-image: -ms-linear-gradient(right, #8349d1, #443ad2);
  background-image: linear-gradient(to left, #8349d1, #443ad2);
}

.App {

}



header.connect-header {
  position: fixed;
  top: 20px;
  right: 50px;
  width: auto;
  z-index: 1000;
  transition: top 1s ease-in-out;
}

header.connect-header button {
  height: 40px;
  line-height: 36px;
  padding: 0px 20px 0px 50px;
  display: inline-block;
  margin: 0px;
  font-family: 'Rubik', sans-serif;

  border-radius: 5px;
  background-color: rgba(255,255,255,1);
  background-repeat: no-repeat;
  background-position: 5px center;
  background-size: 40px auto;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.25);
  transition: background-color 1s ease-in-out, color 1s ease-in-out, border-radius 1s ease-in-out;
}

header.connect-header button:hover {
  cursor: pointer;
  background-color: rgba(255,255,255,0.9);
  color: #6141d2;
  border-radius: 10px;
}

header.connect-header button.connectWC {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: 2px;
  background-image: url(images/buttonIcon_WalletConnect.svg);
}

header.connect-header button.connectWeb3 {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-image: url(images/buttonIcon_Metamask.svg);
}

header.connect-header .label {
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 13px;
  text-align: right;
}

.bgOwl {
  width: 100%;
  height: 100%;
  display: block;
  background-image: url(images/owl-custom-still.svg);
  background-repeat: no-repeat;
  background-position: calc(100% + 100px) 40px;
  background-size: 1000px auto;

  mix-blend-mode: lighten;
  opacity: 0.55;

  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}

.logo {
  display: block;
  font-size: 0px;
  text-indent: -50000em;

  /* background: url(../i/logo-owl-top.svg); */
  background: url(images/logo-owl-top-animated.svg);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
}


.mainApp .logo {
  width: 200px;
  height: 140px;
  margin: 0px auto;
  position: absolute;
  left: calc(50% - 100px);
  top: -70px;
  background-size: 200px auto;
  background-position: top center;
}


footer {
  color: #FFFFFF;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 30px;
  font-size: 13px;
  box-shadow: inset 0px 10px 20px rgba(0,0,0,0.25);
  position: relative;
  z-index: 400;
}

footer a,
footer a:hover {
  color: #FFFFFF;
}


footer .logo {
  width: 150px;
  height: 150px;
  margin: 30px auto;
}

footer .logo a {
  font-size: 0px;
  text-indent: -5000em;
  width: 100%;
  height: 100%;
  display: block;
}

.joinUsOn {
  text-align: center;
  line-height: 40px;
  padding-top: 20px;
}

.joinUsOn span.label {
  color: #FFFFFF;
  font-weight: 300;
  padding-right: 10px;
}

.socialLinks a,
.joinUsOn a {
  text-decoration: none;
  font-size: 0px;
  display: inline-block;
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  vertical-align: top;
  margin: 0px 5px;
  opacity: 0.8;
  transition: opacity 0.5s ease-in-out;
}

.socialLinks a {
  height: 50px;
  height: 50px;
}

.socialLinks a:hover,
.joinUsOn a:hover {
  opacity: 1;
}


.socialLinks a.discord,
.joinUsOn a.discord {
  background-image: url(images/socialDiscord.svg);
}

.socialLinks a.twitter,
.joinUsOn a.twitter {
  background-image: url(images/socialTwitter.svg);
}

.socialLinks a.telegram,
.joinUsOn a.telegram {
  background-image: url(images/socialTelegram.svg);
}

.socialLinks a.github,
.joinUsOn a.github {
  background-image: url(images/socialGithub.svg);
}

.socialLinks a.reddit,
.joinUsOn a.reddit {
  background-image: url(images/socialReddit.svg);
}

.socialLinks a.instagram,
.joinUsOn a.instagram {
  background-image: url(images/socialInstagram.svg);
}


.socialLinks a.lens,
.joinUsOn a.lens {
  background-image: url(images/socialLens.svg);
}


.socialLinks a.mirror,
.joinUsOn a.mirror {
  background-image: url(images/socialMirror.svg);
}


.socialLinks a.link3,
.joinUsOn a.link3 {
  background-image: url(images/socialLink3.svg);
}

.socialLinks a.snapshot,
.joinUsOn a.snapshot {
  background-image: url(images/socialSnapshot.svg);
}

.mainApp {
  position: relative;
  z-index: 500;

  width: 100%;
  display: block;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  background-color: #FFFFFF;
  box-shadow: 0px -40px 40px rgba(0,0,0,0.10);
  position: relative;

  margin-top: 70px;
  padding-bottom: 300px;
  padding-top: 100px;


  background-image: url(images/devOwl.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 1280px auto;

}


.mainApp .mainAppInner  {
  width: 1000px;
  text-align: center;
  margin: 0 auto;
}

.flowers {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: blue;
}

.flower {
  width: 64px;
  height: 128px;
  display: block;
  position: absolute;
  bottom: 0px;
  background-color: red;
  background: url(images/MIVA-Flower-animated.svg) no-repeat center bottom / 100% auto;
  transform-origin: bottom;
}


.flower-1 {
  left: 10%;
  transform: scale(0.6);
}

.flower-2 {
  left: 12%;
  transform: scale(0.8);
}

.flower-3 {
  left: 14%;
}

.flower-4 {
  left: 15%;
  transform: scale(0.8);
}

.flower-5 {
  left: 75%;
  transform: scale(1.1);
}

.flower-6 {
  left: 77%;
}

.flower-7 {
  left: 80%;
  transform: scale(0.35);
}

.flower-8 {
  left: 78%;
  transform: scale(0.7);
}

.flower-9 {
  left: 74%;
  transform: scale(0.55);
}

.flower-10 {
  left: 76%;
  transform: scale(0.4);
}

.leftSide {
  width: 300px;
  float: left;
}

.rightSide {
  width: 650px;
  float: right;
}


.explanations {
  background: rgba(0,0,0,0.0);
  box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
  border-radius: 20px;
  margin-bottom: 50px;
  border-top: 4px solid #6141d2;
  padding: 10px 15px;
  text-align: left;
  font-size: 15px;
}

.explanations h3 {
  font-size: 20px;
  text-transform: uppercase;
  color: rgba(0,0,0,0.8);
}

.explanations h4 {
  font-size: 15px;
  text-transform: uppercase;
  color: rgba(0,0,0,0.7);
  margin-bottom: 0px;
  font-weight: 300;
}


.explanations ul,
.explanations ol {
  padding-left: 25px;
  margin-bottom: 30px;
}

.explanations ul li,
.explanations ol li{
  margin-bottom: 5px;
}



.farmWrapper {
  padding-bottom: 100px;
}

.farmList {
}


.farmList .farm {
  background: rgba(0,0,0,0.0);
  box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
  border-radius: 20px;
  margin-bottom: 50px;
  border-top: 4px solid #6141d2;
  padding: 10px;
  position: relative;
}

.farm h3 {
  margin-top: 4px;
}


.farm figure {
  float: left;
  padding-left: 10px;
  padding-top: 15px;
}

.farm .textWrap {
  float: right;
  padding-right: 10px;
  width: 280px;
}

.farm h3 span {
  font-weight: 300;
}

.farm .tokenSymbols {
  width: 70px;
  height: 40px;
  display: block;
  position: absolute;
  top: 15px;
  right: 20px;
}

.farm .tokenSymbols .tokenSymbol1,
.farm .tokenSymbols .tokenSymbol2 {
  height: 40px;
  width: 40px;
  display: block;
  border-radius: 100%;
  position: absolute;
  top: 0px;
  right: 0px;


  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.farm .tokenSymbols .tokenSymbol1 {
  z-index: 2;
  right: 30px;
}

.farm .tokenSymbols .tokenSymbol2 {
  z-index: 1;
}

.farm #MIVA {
  background-image: url(images/MIVA-Token.svg);
}

.farm #WXDAI {
  background-image: url(images/WXDAI-Token.svg);
}

.farm #HNY {
  background-image: url(images/HNY-Token.svg);
}

.farm #FRACTION {
  background-image: url(images/FRACTION-Token.svg);
}

.farm #SYMM {
  background-image: url(images/SYMM-Token.svg);
}



.farm .contract {
  margin-bottom: 0px;
}

.farm .stats {
  font-size: 12px;
  margin-bottom: 0px;
}


.farm .contract a {
  font-size: 13px;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 1px;
}

.farm .usefulLinks {
  display: none;
}

.usefulLinks {
  width: 100%;
  padding-top: 5px;
  text-align: left;
}

.usefulLinks a {
  font-size: 12px;
  margin-right: 10px;
  padding: 0px 10px;
  border: 1px solid rgba(0,0,0,0.2);
  text-decoration: none;
  display: inline-block;
  margin-bottom: 3px;
  border-radius: 5px;
}

.farm .usefulLinks span {
  display: block;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}


.farm .yourLP {
  position: relative;
  padding-bottom: 10px;
  padding-top: 20px;
}

.farm .yourLPAmount {
  font-size: 70px;
  text-align: right;
  font-weight: 500;
  line-height: 40px;
}

.farm .yourLP p {
  position: absolute;
  left: 0px;
  bottom: 18px;
}

.farm .yourLPAmount span {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  display: block;
  text-align: right;
}

.farm .reward {
  text-align: left;
  display: block;
  padding-bottom: 50px;
  border-bottom: 2px solid #CCCCCC;
  margin-bottom: 30px;

}

.farm .rewardAmount {
  float: right;
  text-align: right;
  font-weight: 300;
  font-size: 14px;
}

.farm .reward p {
  float: left;
}

.farm .rewardAmount span {
  font-size: 22px;
  font-weight: 500;
}


.farm .formWrap {
  display: block;
}

.farm .formWrap p,
.farm .yourLP p,
.farm .reward p {
  text-align: left;
  margin: 0px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
}

.farm .amountField {
 float: left;
}

.farm .amountField input {
 border: 1px solid rgba(0,0,0,0.5);
 border-right: 0px;
 border-top-left-radius: 5px;
 border-bottom-left-radius: 5px;
 height: 38px;
 padding: 0px 20px;
 font-size: 18px;
 width: 153px;
 font-family: 'Rubik', sans-serif;
 font-weight: 300;
}

.farm .maxButton button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.farm .maxButton button:hover {
}

.farm .excButton  {
  clear: both;
  padding-top: 10px;
}

.farm .excButton button  {
  padding: 0px;
  width: 100%;
  font-size: 24px;
  text-transform: uppercase;
  height: 50px;
  line-height: 50px;
}


.mainApp button,
a.button {
  background-image: -webkit-linear-gradient(right, #443ad2, #8349d1);
  background-image: -moz-linear-gradient(right, #443ad2, #8349d1);
  background-image: -o-linear-gradient(right, #443ad2, #8349d1);
  background-image: -ms-linear-gradient(right, #443ad2, #8349d1);
  background-image: linear-gradient(to left, #443ad2, #8349d1);

  height: 40px;
  padding: 0px 30px;
  display: inline-block;
  border-radius: 5px;
  border: 0px solid #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.2);

  background-color: #FFFFFF;
  margin-top: 0px;

  line-height: 40px;
  font-weight: 500;
  text-decoration: none;
  color: #FFFFFF;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;

  transition: box-shadow 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.mainApp button:hover,
a.button:hover  {
  box-shadow: 0px 4px 15px rgba(0,0,0,0.5);
  cursor: pointer;
}

.mainApp button.disabled,
.mainApp button:disabled,
.mainApp button[disabled] {
  opacity: 0.5;
  filter: grayscale();
}

.mainApp button.disabled:hover,
.mainApp button:disabled:hover,
.mainApp button[disabled]:hover {
  cursor: default;
  padding: 0px 30px;
}

.betaNote {
  font-style: italic;
  font-size: 14px;
}



.sectionSeparator {
  background: #f2f2f2;
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
  height: 5px;
  margin-bottom: 100px;
}






/* Temp */

.nft-list {
  width: 100%;
  text-align: center;
}

.nft {
  background: rgba(0,0,0,0.0);
  box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
  border-radius: 20px;
  border-top: 4px solid #6141d2;
  padding: 10px;
  width: 290px;
  height: 570px;
  display: inline-block;
  margin: 0px 10px 50px 10px;
  vertical-align: top;
  position: relative;
  padding-bottom: 30px;
}

.nftButtonWrapper {
  position: absolute;
  bottom: 10px;
  width: 290px;

  border-top: 2px solid rgba(0,0,0,0.05);
  padding-top: 15px;
}

.nft button {
  display: block;

}

.nft button:hover {

}

.nft .levelup {
  float: left;
  border-bottom-left-radius: 15px;
}

.nft .stopfarming {
  float: right;
  border-bottom-right-radius: 15px;
}



.nft .id {
  position: absolute;
  background: #6141d2;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  display: block;
  margin: 0px;
  padding: 0px 20px;
  height: 40px;
  top: -25px;
  right: 0px;


  line-height: 40px;
  text-align: center;

  font-weight: 500;
  color: #FFFFFF;
}


.nft figure {
  padding-top: 15px;
  padding-bottom: 5px;
}

.nft figure img {
  width: 270px;
  height: 270px;
}


.nft .item {
  border-bottom: 1px solid #D1D1D1D1;
  padding-top: 4px;
  padding-bottom: 2px;
  font-size: 16px;
  display: block;
  position: relative;
  text-align: left;
}

.nft .item.lastItem {
  border-bottom: none;
}

.nft .hide {
  display: none;
}

.nft .item .label {
  font-size: 13px;
  font-weight: 500;
  width: 120px;
  display: block;
  position: absolute;
  bottom: 0px;
}

.nft .item .info {
  display: block;
  padding-left: 100px;
  text-align: right;
}

.nft .item .eporioNFT {
  text-decoration: none;
  color: #0097a7;
  background: url(images/eporioSymbol.svg) no-repeat left center;
  background-size: 100 auto;
  padding-left: 30px;
}

.nft .item .niftyFairNFT {
  text-decoration: none;
  color: #000000;
  background: url(images/niftyFairSymbolNFTs.svg) no-repeat right center;
  background-size: 100 auto;
  padding-right: 25px;
}



input.sendNFT {
  width: calc(100% - 20px);
  padding: 0px 8px;
  border: 1px solid rgba(0,0,0,0.25);
  border-bottom-color: #FFFFFF;
  height: 40px;
  line-height: 40px;
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 11px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

button.sendNFTButton {
  width: calc(100% - 2px);
  margin-bottom: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  position: relative;
  left:1px;
}




.flowRateWrap {
  width: auto;
  padding-top: 15px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.farm .flowRateWrap h3 {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}

.flowRateWrap .flowRate {
  height: 50px;
  display: block;
  width: calc(100% - 6px);
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.25);
  padding: 2px;
  line-height: 50px;
  color: #FFFFFF;
  font-weight: 500;
  text-indent: 20px;
}

.flowRateWrap .flowRate .flowRateBar {
  width: 0%;
  height: 100%;
  display: block;
  border-radius: 4px;
  background-color: #443ad2;
}


.flowRateAnim {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 4px;
  background: url(images/flowRateAnim.svg) no-repeat center right;
  background-size: cover;
}

.flowRateAnimOverlay {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-image: -webkit-linear-gradient(right, transparent, #8349d1);
  background-image: -moz-linear-gradient(right, transparent, #8349d1);
  background-image: -o-linear-gradient(right, transparent, #8349d1);
  background-image: -ms-linear-gradient(right, transparent, #8349d1);
  background-image: linear-gradient(to left, transparent, #8349d1);
}

.eporio {
  margin-bottom: 50px;
}

.eporio a {
  border-radius: 5px;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  display: block;


  background-color: #0097a7;
  background-image: url(images/eporioLogoWhite.svg);
  background-repeat: no-repeat;
  background-position: center 15px;
  background-size: auto 40px;
  padding-top: 50px;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.25);

  transition: background-color 1s ease-in-out, color 1s ease-in-out;
}

.eporio a:hover {
  background-color: #8349d1;
}

.conenctFirst {
  border: 2px solid rgba(0,0,0,0.25);
  color: rgba(0,0,0,0.8);
  border-radius: 5px;
  font-size: 20px;
  margin: 0 auto;
  display: inline-block;
  padding: 10px 30px;
  margin-bottom: 80px;
  font-style: italic;
}

.maxedOutWrap {
  display: block;
  padding: 12px;
  border: 2px solid #cf0000;
  border-radius: 10px;

  text-shadow: 0px 1px 1px rgba(0,0,0,0.2);
  background: #FFFFFF;
  /* background-image: linear-gradient(230deg, #b60000, #880000); */
}


.maxedOutWrap p {
  margin: 0px;
  color: #000000;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0px;
  text-shadow: none;
}

.maxedOutWrap .button {
  font-size: 14px;
  margin-top: 11px;
  margin-bottom: 0px;
  text-transform: none;
  border: 0px solid #000000;
  text-shadow: none;

}

.maxedOutBadge {
  width: 140px;
  height: 140px;
  display: block;
  position: absolute;
  left: -13px;
  top: -15px;
  background: url(images/maxedOutBadge.png) no-repeat left top;
  background-size: 100% auto;
}


.superfluidStreamWrap {
  text-align: right;
  padding: 5px 10px;
}

.superfluidStream {
  text-decoration: none;
  border-radius: 4px;
  padding: 0px 10px;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  font-size: 14px;
  color: #000000;
  /*
  color: #FFFFFF;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.25);
  background: #000000;
  transition: background-color 0.5s ease-in-out;
  */
}

.superfluidStream span {
  font-size: 0px;
  text-indent: -5000em;
  background: url(images/superfluidLogo.svg) no-repeat center center;
  background-size: 100% auto;
  width: 100px;
  height: 25px;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;

}

.superfluidStream:hover {
  color: #3f3f3f;
}

.loadingAnimation {
  border: 2px solid rgba(0,0,0,0.15);
  border-radius: 20px;
  margin-bottom: 50px;
}

.loadingAnimation img {
  margin: 0 auto;
  width: 256px;
  height: 256px;
}



.reverseUppercase {
  text-transform: none;
}


.niftyFairBottom a {
  border-radius: 5px;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
  font-size: 20px;
  display: block;


  background-color: #000000;
  background-image: url(images/niftyFairSymbolNFTs.svg);
  background-repeat: no-repeat;
  background-position: center 25px;
  background-size: auto 50px;
  padding-top: 80px;
  padding-bottom: 20px;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.25);

  transition: background-color 1s ease-in-out, color 1s ease-in-out;
}

.niftyFairBottom a:hover {
  color: #00ffc4;
}

.niftyFairBottomCollections {
  padding-top: 40px;
  padding-bottom: 100px;
  display: block;
  clear: both;
}

.niftyFairBottomCollections span {
  display: block;
  width: 100%;
  padding-bottom: 20px;
}

.niftyFairBottomCollections a {
  border-radius: 5px;
  color: #FFFFFF;
  text-decoration: none;
  background-color: #000000;
  padding: 10px 20px;
  margin: 5px;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
  display: inline-block;
}

.niftyFairBottomCollections a:hover {
  background-color: #00ffc4;
  color: #000000;
}


.closedFarms {
  margin-bottom: 50px;
}


.closedFarm {
  width: calc(100% - 2px);
  border-radius: 15px;
  padding: 0px;
  margin: 0px;

  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: left;

  color: ##252525;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.2);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  position: relative;
}

.closedFarm.gc-hs-MIVA-HNY {
  background-image: linear-gradient(to left, #FBAF40, #8349D1);
}

.closedFarm.gc-sy-MIVA-SYMM {
  background-image: linear-gradient(to left, #fb6706, #8349D1);
}

.closedFarm.gc-sy-MIVA-FRACTION {
  background-image: linear-gradient(to left, #000000, #8349D1);
}

.closedFarm .closedFarmInner {
  background-color: #FFFFFF;
  margin: 0px 10px 10px 10px;
  padding: 5px 10px;
  border-radius: 10px;
}

.closedFarm h3 {
  font-size: 22px;
  padding-top: 10px;
  margin-bottom: 0px;
  padding-left: 20px;
  line-height: 22px;
}

.closedFarm h3 a {
  text-decoration: none;
  color: #FFFFFF;
  text-shadow: 0px 1px 3px rgba(0,0,0,0.4);
}

.closedFarm .closedFarmTimespan {
  color: #FFFFFF;
  font-size: 12px;
  margin: 0px 0px 5px 0px;
  padding-left: 20px;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
}


.closedFarm .label {
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  width: 80px;
  text-transform: uppercase;
  line-height: 16px;
  margin-top: 5px;
}

.closedFarm .value,
.closedFarm a.value {
  font-size: 16px;
  text-decoration: none;
  color: #252525;
  width: calc(100% - 80px);
  display: inline-block;
  line-height: 16px;
}

.smallText {
  font-size: 11px;
}

.closedFarm p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.closedBadge {
  width: 80px;
  height: 80px;
  display: block;
  position: absolute;
  right: -7px;
  top: -6px;
  background: url(images/closedFarmBadge.png) no-repeat left top;
  background-size: 100% auto;
}

.explanations iframe {
  border-radius: 15px;
  margin-top: 5px;
}

.explanations.video {
  padding: 0px 5px;
}


@media screen and (min-width: 768px) and (max-width: 1000px) {


}

.mobileOnly {
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 767px) {

  .mobileOnly {
    display: block;
  }

  .mainApp {
    margin-top: 150px;
  }

  .mainApp .mainAppInner {
    width: calc(100% - 30px);
    padding-left: 15px;
    padding-right: 15px;
  }

  header.connect-header {
    position: absolute;
    right: 0px;
    width: 100%;
    text-align: center;
  }

  header.connect-header .label {
    text-align: center;
  }

  header.connect-header button {
    font-size: 9px;
  }

  h1 {
    font-size: 20px;
    top: 100px;
    height: 40px;
    left: 0px;
    text-align: center;
    width: 100%;
    display: block;
    padding: 0px 0px 30px 0px;
    background-position: center top;
    background: none;
  }

  h2 {
    line-height: 36px !important;
    margin-bottom: 20px;
  }

  .flower-1 { left: 14%; }
  .flower-2 { left: 18%; }
  .flower-3 { left: 22%; }
  .flower-4 { left: 26%; }
  .flower-5 { left: 70%; }
  .flower-6 { left: 74%; }
  .flower-7 { left: 78%; }
  .flower-8 { left: 65%; }
  .flower-9 { left: 80%; }
  .flower-10 { left: 66%; }


  .leftSide,
  .rightSide {
    width: 100%;
    float: none;
  }


  .farm h3 {
    padding-top: 50px;
  }

  .farm .flowRateWrap h3 {
    padding-top: 0px;
  }


  .farm h3 span {
    display: block;
  }

  .farm .tokenSymbols {
    right: calc(50% - 35px);
  }

  .farm figure,
  .farm .textWrap {
    float: none;
    padding: 15px;
    width: auto;
  }

  .farm figure img {
    width: 100%;
    height: auto;
  }

  .farm .amountField {
    width: calc(100% - 112px);
  }

  .farm .amountField input {
    width: 100%;
  }

  .farm .maxButton {
    width: 70px;
    float: right;
  }


  .farm .maxButton button {
    width: 70px;
    padding: 0px;
  }

  .farm .contract a {
    font-size: 8px;
    word-wrap: break-word;
    line-height: 10px;
  }

  .maxedOutWrap .button {
    display: block;
  }

  .eporio a {
    line-height: 24px;
    padding-top: 65px;
    padding-bottom: 10px;
    height: auto;
  }


  .mobileShowContainer {
    display: none;
  }

  .explanations {
      margin-bottom: 30px;
  }

  .explanations h3 {
    position: relative;
  }

  .explanations h3:hover {
    cursor: pointer;
  }

  .explanationFirst h3:hover {
    cursor: default;
  }

  .openArrow {
    position: absolute;
    top: 10px;
    right: 0px;
    background: url(images/arrowDown.svg) no-repeat center;
    background-size: 20px auto;

    width: 20px;
    height: 20px;
    display: block;
  }


  .openArrow.hide {
    display: none;
  }
  .mobileShowContainer.show {
    display: block;
  }

  .niftyFairBottomCollections {
    margin-bottom: 50px;
  }
  
  .niftyFairBottomCollections a {
    display: inline-block;
  }



}

@media screen and (min-width: 1px) and (max-width:341px) {
  .nft {
    margin-left: 0px;
    margin-right: 0px;
  }
}



body>iframe {
  display: none;
}